// events
import elite2025 from '../imgs/eventi/2025-elite.webp';
import boxe2024 from '../imgs/eventi/2024-boxe.webp';
import woman2024 from '../imgs/eventi/2024-like-woman.webp';
import worldChampions2024 from '../imgs/eventi/2024-world-champions.webp';
import OnechampionshipK1Max2023 from '../imgs/eventi/2023-onechampionship-k1-max.webp';
import kickK1Boxe2023 from '../imgs/eventi/2023-kick-k1-boxe.webp';
import pugilato2023 from '../imgs/eventi/2023-pugilato.webp';
import worldChampions2022 from '../imgs/eventi/2022-world-champions.webp';
import lightContact2022 from '../imgs/eventi/2022-light-contact.webp';
import pugilato2022 from '../imgs/eventi/2022-pugilato.webp';
import worldChampions2021 from '../imgs/eventi/2021-world-champions.webp';
import lightContact2021 from '../imgs/eventi/2021-light-contact.webp';
import pugilato2021 from '../imgs/eventi/2021-pugilato.webp';
// sponsors
import superior from '../imgs/sponsors/superior.webp';
import edera from '../imgs/sponsors/edera.webp';
import sap from '../imgs/sponsors/sap.webp';
import leone from '../imgs/sponsors/leone.webp';
import federkombat from '../imgs/sponsors/federkombat.webp';
import ko from '../imgs/sponsors/ko.webp';
import thaimassage from '../imgs/sponsors/thai-massage.webp';
import fighters from '../imgs/sponsors/fighters.webp';
import pugileTastiera from '../imgs/sponsors/pugile-tastiera.webp';


export default [	
   {
      id: 12,
      src: elite2025,
      name: 'Combat Camp: Elite Edition',
      date: '16-17-18 maggio 2025',
      text: 'ATTENZIONE appassionati di sport da combattimento! Maggio è il mese del Combat CAMP, finalmente è arrivato il momento tanto atteso per chi desidera partecipare ad una vacanza allenamento dove mare, spiaggia e piscina faranno da cornice ad un evento multistage su misura per te dove poter conoscere i migliori Fighters italiani e stranieri, allenarti con loro e condividere bei momenti. Avremo ospiti speciali dalla Thailandia e da diverse città italiane insieme a tanti campioni e atleti provenienti da tutta la penisola. Yodpt Petchrungruang, Angelo Volpe, Mattia Faraoni e Mirko Flumeri saranno la nuova squadra di formatori di questa fiammante edizione elite! Un\’esperienza unica per allenarti con i grandi nomi del settore e per confrontarti con altri appassionati come te! Iscrizioni aperte a partire dal 3 febbraio.',
      athletes: [ 'Yodpt Petchrungruang', 'Angelo Volpe', 'Mattia Faraoni', 'Mirko Flumeri' ],
      sponsors: [
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         },
         {
            src: sap,
            alt: 'SAP Fighting Style'
         }
      ]
   },
   {
      id: 11,
      src: boxe2024,
      name: 'Boxing Camp: il Combat Camp di pugilato',
      date: '7-8 settembre 2024',
      text: 'Mike Tyson diceva: fuori dal ring tutto è così noioso! E noi del Combat Camp non riusciamo proprio ad annoiarci... ed eccoci qua a presentare La vacanza allenamento esclusiva per chi vuole perfezionare la parte pugilistica vivendo un week end con 2 tra i più grandi campioni di pugilato che abbiamo in Italia. Dopo l\'enorme successo dello scorso anno e gli ottimi feedback ricevuti abbiamo deciso di coinvolgere nuovamente il grande pugile e campione Giovanni De Carolis, il quale non ha certo bisogno di presentazioni! Nuovo formatore invece per la prima volta nel nostro staff, soprannominato "Il Terribile" Ivan Zucco uno dei volti più interessanti del panorama nazionale, l\'artista del Ko, primissima volta in stage in Romagna!',
      athletes: [ 'Giovanni De Carolis', 'Ivan Zucco' ],
      sponsors: [
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         },
         {
            src: leone,
            alt: 'Leone 1947'
         }
      ]
   },
   {
      id: 10,
      src: woman2024,
      name: 'Combat Camp femminile: Fight Like a Woman',
      date: '22-23 giugno 2024',
      text: 'Con grande soddisfazione presentiamo il secondo Combat Camp di questo 2024 ricco di appuntamenti! Un Combat Camp pensato per il pubblico femminile e per tutte le Fighters praticanti di kickboxing, k-1, Light Contact e Pugilato! Grazie alla presenza di 2 campionesse potrete allenarvi mattina e pomeriggio per 2 giornate intere nella nostra location sulla spiaggia, alternando momenti di formazione a momenti di svago in piscina e al mare; le lezioni saranno dirette dalla pluricampionessa del mondo Silvia La Notte per quanto riguarda la Kickboxing/k-1 e Light Contact , mentre per il pugilato avremo la grandissima campionessa Silvia Bortot! Evento a numero chiuso! Iscrizioni aperte anche in piattaforma federale Federkombat Emilia Romagna.',
      athletes: [ 'Silvia La Notte', 'Silvia Bortot' ],
      sponsors: [
         {
            src: sap,
            alt: 'SAP Fighting Style'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         },
         {
            src: federkombat,
            alt: 'Feder Kombat Italia - Comitato Regionale Emilia Romagna'
         }
      ]
   },
   {
      id: 9,
      src: worldChampions2024,
      name: 'Camp di Muay Thai e Kickboxing K1 Rules',
      date: 'Il miglior Combat Camp in Italia dal 24 al 26 maggio 2024',
      text: '4 leggende della muay thai e del k1 guideranno gli allenamenti nel tatami del Combat Camp 2024 condividendo con i partecipanti la loro esperienza e le loro tattiche frutto delle battaglie nei ring più prestigiosi e ambiti del mondo. Le iscrizioni sono ora aperte e i posti sono limitati: non perdere questa occasione, iscriviti ora!',
      athletes: [ 'Tayfun Ozcan', 'Davide Armanini', 'Roberto Gheorghita', 'Alessio Malatesta' ],
      sponsors: [
         {
            src: superior,
            alt: 'Superior Supplement'
         },
         {
            src: sap,
            alt: 'SAP Fighting Style'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         }
      ]
   },
   {
      id: 8,
      src: OnechampionshipK1Max2023,
      name: 'One Champions & k1 World Max Legend Edition',
      date: '1-2-3 settembre 2023',
      text: 'La Tiger Muay Thai di Phuket e la Mejiro Gym Amsterdam si fondono e si trasferiscono in Italia al Combat Camp di Cesenatico. 4 leggende del Glory, Enfusion, k1 world max e One Championship guideranno gli allenamenti durante i 3 giorni nel tatami più infuocato d\'Italia. Grazie a questo concentrato di esperienza nel professionismo il Combat Camp di settembre non ha nulla da invidiare alle più grandi palestre thai. Stiamo parlando dei campioni:',
      athletes: [ 'Martin Meoni', 'Davide Armanini', 'Shan Cangelosi', 'Andy Souwer' ],
      sponsors: [
         {
            src: superior,
            alt: 'Superior Supplement'
         },
         {
            src: sap,
            alt: 'SAP Fighting Style'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         }
      ]
   },
   {
      id: 7,
      src: kickK1Boxe2023,
      name: 'Kickboxing K1 Boxe',
      date: '17-18 giugno 2023',
      text: 'Il secondo Combat Camp del 2023 si svolgerà nel mese di Giugno a Cesenatico nella nostra location sul mare! Camp che inaugurerà l\'inizio dell\'estate con tante sorprese e con uno staff decisamente di alto profilo: fightersxxproject! La particolarità del progetto è di proporre gli sparring totalmente al femminile per uno scambio tra pari, 4 allenamenti per uomini e donne, ragazzi e ragazze con le 2 campionesse della boxe pro Vissia Trovato e Silvia Bortot e le creatrici del progetto Cristina Caruso e Silvia La Notte, super fighters e campionesse d\'Italia kick e k1.',
      athletes: [ 'Silvia La Notte', 'Silvia Bortot', 'Cristina Caruso', 'Vissia Trovato' ],
      sponsors: [
         {
            src: fighters,
            alt: 'Fighters Project'
         },
         {
            src: pugileTastiera,
            alt: 'Pugile da Tastiera'
         },
         {
            src: thaimassage,
            alt: 'Thai Massage Estetica Sima'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         }
      ]
   },
   {
      id: 6,
      src: pugilato2023,
      name: 'Camp di Pugilato',
      date: '20-21 maggio 2023',
      text: 'Iniziamo a presentare il primo Combat Camp di Pugilato di questo 2023 che si terrà sabato 20 e domenica 21 maggio presso una location affacciata sul mare con tanti servizi tra cui piscina, spiaggia pvt, palestre, ristorante e camere con ogni comfort direttamente sulla spiaggia di Cesenatico. Nella cabina di regia i formatori Giovanni De Carolis e Mattia Faraoni! Opportunità unica di conoscere dal vivo due grandissimi campioni, i quali condivideranno con tutti i partecipanti i loro schemi di lavoro, preparazione, tattica e tanta esperienza che li ha portati ad avere ottimi risultati nel loro percorso agonistico.',
      athletes: [ 'Giovanni De Carolis', 'Mattia Faraoni' ],
      sponsors: [
         {
            src: superior,
            alt: 'Superior Supplement'
         },
         {
            src: sap,
            alt: 'SAP Fighting Style'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         }
      ]
   },
   {
      id: 5,
      src: worldChampions2022,
      name: 'World Champions',
      date: '9-10-11 settembre 2022',
      text: 'Le cose belle acquisiscono maggior valore se si fanno un pò desiderare... Ma oggi è arrivato il momento di svelare la locandina ufficiale con la squadra al completo di questa quinta edizione targata Combat Camp K1 & Muay Thai di Settembre! I formatori saranno Davide Armanini, Silvia La Notte, Itthipol Akkasrivorn per la Muay Thai e il grande Campione Glory Robin Van Robin Roosmalen! Le iscrizioni sono aperte: non perdere la possibilità di essere dei nostri, iscriviti ora!',
      athletes: [ 'Silvia La Notte', 'Itthipol Akkasrivorn', 'Davide Armanini', 'Robin Roosmalen' ],
      sponsors: [
         {
            src: thaimassage,
            alt: 'Thai Massage Estetica Sima'
         },
         {
            src: superior,
            alt: 'Superior Supplement'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         }
      ]
   },
   {
      id: 4,
      src: lightContact2022,
      name: 'Light / Point / Kick Full Contact',
      date: '25-26 giugno 2022',
      text: 'Iniziamo col presentare il secondo Combat Camp del 2022. Le discipline trattate in questa edizione saranno il Light Contact, il Point Fighting, la Kick Light e il Full Contact con 4 formatori tra i migliori al mondo: Vi aspettiamo!',
      athletes: [ 'Timothy Bos', 'Francesca Ceci', 'Davide Colla', 'Camilla Marennghi' ],
      sponsors: [
         {
            src: superior,
            alt: 'Superior Supplement'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         },
         {
            src: federkombat,
            alt: 'Feder Kombat Italia - Comitato Regionale Emilia Romagna'
         }
      ]
   },
   {
      id: 3,
      src: pugilato2022,
      name: 'Camp di Pugilato',
      date: '28-29 maggio 2022',
      text: 'È iniziato il conto alla rovescia per il secondo Combat Camp di Pugilato, il 28 e 29 maggio il campione del mondo IBO Michael LoneWolf Magnesi e il grande maestro Alessandro Duran saranno ufficialmente i formatori della seconda edizione Combat Camp Pugilato.',
      athletes: [ 'Alessandro Duran', 'Michael Magnesi' ],
      sponsors: [
         {
            src: superior,
            alt: 'Superior Supplement'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         }
      ]
   },
   {
      id: 2,
      src: worldChampions2021,
      name : 'World Champions Edition',
      date: '3-4-5 settembre 2021',
      text: 'Questa edizione vedrà 4 campioni mondiali alternarsi sul tatami del Combat Camp condividendo con i fortunati partecipanti esperienza e sudore frutto delle loro battaglie nei ring di tutto il mondo. Parliamo di Davide Armanini, Roberto Gheorghita, Ivan Naccari e Martine Michieletto! Uno pettacolo assicurato ragazzi. Benvenuti al Combat Camp!',
      athletes: [ 'Roberto Gheorghita', 'Davide Armanini', 'Martine Michieletto', 'Ivan Naccari' ],
      sponsors: [
         {
            src: sap,
            alt: 'SAP Fighting Style'
         },
         {
            src: ko,
            alt: 'KO'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         }
      ]
   },
   {
      id: 1,
      src: lightContact2021,
      name : 'Light Contact Point Fighting',
      date: '17-18 luglio 2021',
      text: 'A grande richiesta le realtà del Light Contact e del Point Fight faranno parte di questo progetto Combat Camp di formazione e divertimento, coinvolgendo i due  super top Fighter del momento: Davide Colla pluricampione mondiale di Point Fight e Timothy Bos  plurititolato campione mondiale di Light Contact.',
      athletes: [ 'Davide Colla', 'Thimothy Bos' ],
      sponsors: [
         {
            src: sap,
            alt: 'SAP Fighting Style'
         },
         {
            src: ko,
            alt: 'KO'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         },
         {
            src: federkombat,
            alt: 'Feder Kombat Italia - Comitato Regionale Emilia Romagna'
         }
      ]
   },
   {
      id: 0,
      src: pugilato2021,
      name : 'Camp di Pugilato',
      date: '12-13 giugno 2021',
      text: 'Da quest\'anno il Combat Camp avrà un appuntamento in più: vi presento Combat Camp Pugilato. I formatori di alto livello che prenderanno parte a questo progetto saranno: il Campione Europeo dei pesi medi "Il Giaguaro" Matteo Signani e il campione del mondo dei pesi massimi ed ex ct della nazionale Francesco Damiani.',
      athletes: [ 'Francesco Damiani', 'Matteo Signani' ],
      sponsors: [
         {
            src: sap,
            alt: 'SAP Fighting Style'
         },
         {
            src: edera,
            alt: 'K-1 Kickboxing Edera Forlì'
         }
      ]
   }
];
